<template>
  <div
    class="w-100 h-100 d-flex align-items-center justify-content-center"
  >
    <b-spinner
      style="width: 4rem; height: 4rem;"
      variant="info"
      label="Large Spinner"
    ></b-spinner>
  </div>
</template>

<script>
export default {
  name: 'Loader2',
};
</script>
